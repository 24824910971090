import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { JObject } from "../../../../common/data/models";
import { BalanceMapper, OrdersCountsMapper, OrdersChartMapper, SalesMapper, PartnersCountMapper, OrdersTotalsChartMapper, OrdersPerZoneChartMapper } from "../mappers";
import { BalanceDto, OrdersChartDto, OrdersCountsDto, OrdersPerZoneChartDto, OrdersTotalsChartDto, PartnersChartDto, PartnersCountsDto, SalesDto } from "../../domain";
import { DateUtils } from "../../../../helpers/date";
import { PartnersChartMapper } from "../mappers/partners-chart";
import { OrdersPerStateChartMapper } from "../mappers/orders_per_state_chart";

export class DashboardRepository {

    /***
     * getOrdersCount
     */
    public getOrdersCount(): Promise<OrdersCountsDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.DASHBOARD_ORDERS_COUNT, {}, undefined, undefined, 'error')
            .then(res => OrdersCountsMapper.fromJson({ ...res.data }));
    }

    public getOrdersChartsCounts(): Promise<OrdersChartDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.DASHBOARD_ORDERS_CHARTS_COUNTS, {}, undefined, undefined, 'error')
            .then(res => OrdersChartMapper.fromJson(res.data));
    }

    /**
     * getSalesCount
     */
    public getSalesCount(): Promise<SalesDto> {
        return tawreedHttpService
            .post<JObject, number>(endpoints.DASHBOARD_SALES_TOTAL, {}, undefined, undefined, 'error')
            .then(res => SalesMapper.fromJson(res.data));
    }

    /**
     * getBalance
     */
    public getBalance(): Promise<BalanceDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.DASHBOARD_ACCOUNTS_BALANCE, {}, undefined, undefined, 'error')
            .then(res => BalanceMapper.fromJson(res.data));
    }

    /**
     * PartnersCountsDto
     */
    public getPartnersCounts(): Promise<PartnersCountsDto> {
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.DASHBOARD_PARTNERS_COUNT, {}, undefined, undefined, 'error')
            .then(res => PartnersCountMapper.fromJson(res.data));
    }

    /**
     * PartnersCountsDto
     */
    public getPartnersChart(startDate: Date, endDate: Date): Promise<PartnersChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.DASHBOARD_PARTNERS_CHART, { startDate: DateUtils.toISO(startDate, 'datetime', 'start'), endDate: DateUtils.toISO(endDate, 'datetime', 'end') }, undefined, undefined, 'error')
            .then(res => PartnersChartMapper.fromJson(res.data));
    }

    /**
     * OrdersTotalsChartDto
     */
    public getOrdersChartsTotals(startDate: Date, endDate: Date): Promise<OrdersTotalsChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.DASHBOARD_ORDERS_TOTALS_CHART, { startDate: DateUtils.toISO(startDate, 'datetime', 'start'), endDate: DateUtils.toISO(endDate, 'datetime', 'end') }, undefined, undefined, 'error')
            .then(res => OrdersTotalsChartMapper.fromJson(res.data));
    }

    /**
     * OrdersPerZoneChartDto
     */
    public getOrdersPerZoneChartData(startDate: Date, endDate: Date): Promise<OrdersPerZoneChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.DASHBOARD_ORDERS_PER_ZONE_CHART, { startDate: DateUtils.toISO(startDate, 'datetime', 'start'), endDate: DateUtils.toISO(endDate, 'datetime', 'end') }, undefined, undefined, 'error')
            .then(res => OrdersPerZoneChartMapper.fromJson(res.data));
    }
    public getOrdersPerStateChartData(startDate: Date, endDate: Date): Promise<OrdersPerZoneChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.DASHBOARD_ORDERS_PER_STATE_CHART, { startDate: DateUtils.toISO(startDate, 'datetime', 'start'), endDate: DateUtils.toISO(endDate, 'datetime', 'end') }, undefined, undefined, 'error')
            .then(res => OrdersPerStateChartMapper.fromJson(res.data));
    }
}
