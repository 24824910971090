import { JObject } from "../../../../common/data/models";
import { PartnerMapper, PartnerType } from "../../../partners/data";
import { TopUpPartner } from "../../domain";

export const TopUpPartnerMapper = {

    fromJson(obj: JObject): TopUpPartner {
        return {
            topUpPartnerId: obj.topUpPartnerId,
            topUpFeesType: obj.topUpFeesType,
            topUpFeesValue: obj.topUpFeesValue,
            platformPercentage: obj.platformPercentage,
            partnerPercentage: obj.partnerPercentage,
            topUpCashBackType: obj.topUpCashBackType,
            topUpCashBackValue: obj.topUpCashBackValue,
            payLaterTerms: obj.payLaterTerms,
            terms: obj.terms,
            minimumTopUp: obj.minimumTopUp,
            maximumTopUp: obj.maximumTopUp,
            topUpCode: obj.topUpCode,
            topUpThroughUs: obj.topUpThroughUs,
            instructionUrl: obj.instructionUrl,
            channel: obj.channel,
            ...PartnerMapper.toDto(PartnerMapper.fromJson(obj)),
            balanceId: obj.balanceId,
        };
    },

    toJson(obj: TopUpPartner): JObject {
        return {
            topUpPartnerId: obj.topUpPartnerId,
            topUpFeesType: obj.topUpFeesType,
            topUpFeesValue: obj.topUpFeesValue,
            platformPercentage: obj.platformPercentage,
            partnerPercentage: obj.partnerPercentage,
            topUpCashBackType: obj.topUpCashBackType,
            topUpCashBackValue: obj.topUpCashBackValue,
            payLaterTerms: obj.payLaterTerms,
            terms: obj.terms,
            minimumTopUp: obj.minimumTopUp,
            maximumTopUp: obj.maximumTopUp,
            topUpCode: obj.topUpCode,
            topUpThroughUs: obj.topUpThroughUs,
            instructionUrl: obj.instructionUrl,
            channel: obj.channel,
            balanceId: obj.balanceId,
            ...PartnerMapper.toJson(PartnerMapper.fromDto({ ...obj, partnerType: PartnerType.TopUp })),
        };
    }
}
