import { PartnerDto } from "../../../partners/domain";
import { UserRole } from "../../data/models/role";

export interface UserDto {
    userId: number | null;
    // username
    email: string | null;
    phoneCode: number | null;
    mobileNo: string | null;
    //
    firstName: string | undefined;
    lastName: string | undefined;
    fullName: string;
    locked: boolean | undefined;
    partner?: PartnerDto | undefined;
    prefLangCode: string | undefined;
    roleCode: UserRole | undefined;
    partnerAdmin?: boolean;
    nationality?: string;
    birthday?: Date;
    suspend?: boolean;
}

export function getInitialUserDto(): UserDto {
    return {
        userId: null,
        firstName: undefined,
        lastName: undefined,
        fullName: '',
        email: null,
        phoneCode: null,
        mobileNo: null,
        partner: undefined,
        locked: undefined,
        prefLangCode: undefined,
        roleCode: undefined,
        partnerAdmin: false,

    }
}
