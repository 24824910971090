import { CssUtils } from "../../../../helpers/css";
import { ObjectUtils } from "../../../../helpers/object";

const TawreedTableDecimalFormatter = (field: string, alt?: any, style?: any, className: string = '') => {
    return {
        dataType: 'numeric',
        body: (rowData: any) => ObjectUtils.toFixed(rowData[field], alt),
        style: CssUtils.align('right', style),
        className: `${CssUtils.className.field.amount} ${className}`,
    }
};

const TawreedTableDropdownFormatter = (field: string, formate: (item: any) => React.ReactNode) => {
    return {
        body: (rowData: any) => {
            const value = ObjectUtils.getNested(rowData, field);
            if (value) {
                return formate(value);
            }
            return '';
        },
    }
};
const TawreedTablePercentageFormatter = (field: string, alt?: any) => {
    return {
        body: (rowData: any) => ObjectUtils.toFixed(rowData[field], alt) + " %"
    }
};
const TawreedTableCurrencyFormatter = (field: string, currencyCode: string, alt?: any) => {
    return {
        body: (rowData: any) => ObjectUtils.toFixed(rowData[field], alt) + ' ' + currencyCode,
    }
};
const TawreedTableCurrencyOrPercentageDependFormatter = (field: string, depend: string, currencyCode: string, alt?: any) => {
    return {
        body: (rowData: any) => ObjectUtils.toFixed(rowData[field], alt) + ' ' + (rowData[depend] === 'percentage' ? "%" : rowData[depend] === "fixed" ? currencyCode : "%+"),
    }
};

export const TawreedTableFormatters = {
    decimal: TawreedTableDecimalFormatter,
    dropdown: TawreedTableDropdownFormatter,
    percentage: TawreedTablePercentageFormatter,
    currency: TawreedTableCurrencyFormatter,
    currencyOrPercentageDepend: TawreedTableCurrencyOrPercentageDependFormatter
}
