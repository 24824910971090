import React, { useState } from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Button } from "primereact/button";
import { CustomerStoreCartDto } from "../../domain";
import { ObjectUtils } from "../../../../../helpers/object";
import { CssUtils } from "../../../../../helpers/css";
import { CartCheckoutDialog } from "./cart-checkout-dialog"

export interface CartFormSummaryProps {
    className: string;
    cart: CustomerStoreCartDto;
    onCheckout: (data?: any) => void;
    disabled: boolean;
    priceListId: number;
    cartId?: number;
}

export const CartSingleFormSummary: React.FC<CartFormSummaryProps> = (props) => {


    const [checkoutPaymentDialog, setCheckoutPaymentDialog] = useState<boolean>(false);
    return (
        <React.Fragment>
            <div className="grid mt-3">
                <div className="col-12 md:col-6">
                    <div className="grid">
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_gross_total_1')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.grossTotal)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_total_discounts')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.totalDiscount)}
                        </div>


                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_sub_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.subTotal)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_total_tax')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.totalTax)}
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_order_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {'JOD ' + ObjectUtils.toLocalString(props.cart.total)}
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Button disabled={props.disabled} type="button" label={tawreedTranslationService.translate('lbl_checkout')} onClick={() => setCheckoutPaymentDialog(true)} className="inputfield w-full" />
                </div>
            </div>

            <CartCheckoutDialog
                priceListId={props.priceListId}
                cartId={props.cartId!}
                onHide={() => { setCheckoutPaymentDialog(!checkoutPaymentDialog) }}
                onAccept={(data) => {
                    console.log(data);
                    setCheckoutPaymentDialog(false);
                    props.onCheckout({
                        paymentModeId: data.paymentMode.paymentModeId,
                        cartId: props.cartId,
                        checkoutMode: data.checkoutMode,
                        note: data.note
                    })
                }}
                visible={checkoutPaymentDialog} />
        </React.Fragment>

    );
}
