import { JObject } from "../../../../common/data/models"
import { InvoiceReportItem, ReportResultDto } from "../../domain"
import { InvoiceReportItemMapper } from "./invoices-item"
import { UrlsMapper } from "./urls"

export const InvoiceReportMapper = {

    fromJson(obj: JObject): ReportResultDto<InvoiceReportItem> {
        return {
            urls: obj.reportUrls ? UrlsMapper.fromJson(obj.reportUrls) : undefined,
            data: {
                status: 200,
                error: undefined,
                message: undefined,
                data: {
                    ...obj.invoices,
                    content: obj.invoices.content?.map((e: JObject) => InvoiceReportItemMapper.fromJson(e))
                }
            },
            totals: undefined,
        }
    }
}