import { JObject } from "../../../../../common/data/models"
import { IBonusType } from "../../../../constants";
import { OrderItemDto } from "../../domain";
import { OrderItem } from "../models"

export const OrderItemMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {OrderItem}
     */
    fromJson: (json?: JObject): OrderItem => {
        return {
            orderItemId: json?.orderItemId,
            cartItemId: json?.cartItemId,
            orderId: json?.orderId,
            expireDate: json?.expireDate,
            productName: json?.productName,
            quantity: json?.quantity,
            quantityCancelled: json?.quantityCancelled,
            quantityOrdered: json?.quantityOrdered,
            quantityReturned: json?.quantityReturned,
            retailPrice: json?.retailPrice,
            returnQuantity: json?.returnQuantity,
            salePrice: json?.salePrice,
            itemTotal: json?.itemTotal,
            cancelledItemTotal: json?.quantityCancelled * json?.salePrice,
            storeName: json?.storeName,
            customerName: json?.customerName,
            orderItemName: `${json?.productName} - ${json?.orderId}`,
            itemGrossTotal: json?.itemGrossTotal,
            tax: json?.tax,
            itemTotalTax: json?.itemTotalTax,
            sellingPrice: json?.sellingPrice,
            discountType: json?.discountType,
            discountValue: json?.discountValue,
            bonus: json?.bonus,
            storeProductTax: json?.storeProductTax,
            bonusType: json?.bonusType,
            bonuses: json?.bonuses ?? [],
        }
    },

    /**
     *
     */
    toJson: (order: OrderItem): JObject => {
        return { ...order };
    },

    /**
     * Create order model from json object
     * @param dto
     * @returns {OrderItem}
     */
    fromDto: (dto: OrderItemDto): OrderItem => {
        return {
            orderItemId: dto.orderItemId!,
            cartItemId: dto.cartItemId!,
            orderId: dto.orderId!,
            expireDate: dto.expireDate!,
            itemTotal: dto.itemTotal!,
            cancelledItemTotal: dto.cancelledItemTotal!,
            productName: dto.productName!,
            quantity: dto.quantity!,
            quantityCancelled: dto.quantityCancelled!,
            quantityOrdered: dto.quantityOrdered!,
            quantityReturned: dto.quantityReturned!,
            retailPrice: dto.retailPrice!,
            returnQuantity: dto.returnQuantity!,
            salePrice: dto.salePrice!,
            storeName: dto.storeName!,
            customerName: dto.customerName!,
            orderItemName: dto.orderItemName!,
            itemGrossTotal: dto.itemGrossTotal!,
            tax: dto.tax!,
            itemTotalTax: dto.itemTotalTax!,
            sellingPrice: dto.sellingPrice!,
            discountType: dto.discountType!,
            discountValue: dto.discountValue!,
            bonus: dto.bonus!,
            storeProductTax: dto.storeProductTax,
            bonusType: dto.bonusType,
            bonuses: dto.bonuses ?? [],
        }
    },

    /**
     *
     */
    toDto: (item?: OrderItem): OrderItemDto => {
        return {
            orderItemId: item?.orderItemId,
            cartItemId: item?.cartItemId,
            orderId: item?.orderId,
            expireDate: item?.expireDate,
            itemTotal: item?.itemTotal,
            cancelledItemTotal: item?.cancelledItemTotal,
            productName: item?.productName,
            quantity: item?.quantity,
            quantityCancelled: item?.quantityCancelled,
            quantityOrdered: item?.quantityOrdered,
            quantityReturned: item?.quantityReturned,
            retailPrice: item?.retailPrice,
            returnQuantity: item?.returnQuantity,
            salePrice: item?.salePrice,
            storeName: item?.storeName,
            customerName: item?.customerName,
            orderItemName: item?.orderItemName,
            itemGrossTotal: item?.itemGrossTotal,
            tax: item?.tax,
            itemTotalTax: item?.itemTotalTax,
            sellingPrice: item?.sellingPrice,
            discountType: item?.discountType,
            discountValue: item?.discountValue,
            storeProductTax: item?.storeProductTax,
            bonusType: item?.bonusType,
            bonuses: item?.bonuses ?? [],
            discountBonus: item?.bonuses?.find(el => el.bonusType === IBonusType.DISCOUNT)?.bonus ?? 0,
            bonus: item?.bonuses?.find(el => el.bonusType === IBonusType.EXTRA_ITEMS)?.bonus ?? 0,

        }
    },
}
