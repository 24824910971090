import React, { useContext } from 'react'
import { TawreedDetailView } from '../../../../../common/components/detail-view';
import { DetailViewProps, DetailViewRefreshCallback } from '../../../../../common/components/detail-view/detail-view.props';
import { PartnerType } from '../../../../partners/data';
import { OrderStatus } from '../../data';
import { OrderDto, OrderStoreService } from '../../domain';
import { OrderForm } from './order-form';
import { tawreedTranslationService } from "../../../../../common/translations";
import { DateUtils } from "../../../../../helpers/date";
import { ObjectUtils } from "../../../../../helpers/object";
import { OrderFormListener } from "./order-form/order-form.props";

import './orders-detail-view.scss';
import { TawreedAction } from '../../../../../common/actions';
import { DataTableSelectionModeType } from 'primereact/datatable';
import { GlobalContext } from '../../../../../context';

export type OrdersDetailViewProps = {
    /**
     * use returned orders context?
     */
    returned?: boolean;

    /**
     * status id
     */
    statusId: OrderStatus;

    /**
     * partner
     */
    partnerType: PartnerType;
    partnerName: string;
    partnerId: number;

    /**
     * carrierId
     */
    carrierId?: number;
    carrierName?: string;

    /**
     * actions
     */
    actions?: Array<TawreedAction>;


    selectionMode?: DataTableSelectionModeType;
    selectionSelectAllOnLoad?: boolean;

    onEmpty(): void;
}

export const OrdersDetailView: React.FC<OrdersDetailViewProps> = (props) => {

    const service: OrderStoreService = React.useMemo(() => new OrderStoreService(), []);

    const { constants: { constants } } = useContext(GlobalContext)

    const onRefreshAsListener = (callback: DetailViewRefreshCallback<OrderDto>): OrderFormListener => {
        return (event) => {
            switch (event.type) {
                case 'item-changes':
                    callback({ name: "refresh", item: event.sender });
                    break;
                default:
                    callback({ name: "refresh-all" });
            }
        }
    }

    const tawreed: DetailViewProps<OrderDto> = {
        title: tawreedTranslationService.translate('lbl_orders_partner_' + props.partnerType + '_' + (props.returned ? 'true' : 'false') + '_' + props.statusId, `${props.partnerName ? ' - ' + props.partnerName : ''}${props.carrierName ? ' - ' + props.carrierName : ''}`),
        dataKey: 'orderId',
        ds: {
            trigger: 'normal_and_action',
            mode: 'lazy',
            onEmpty: props.onEmpty,
            onSearch: (request) => {
                return service.getDetails(
                    request.page,
                    props.statusId,
                    props.returned,
                    props.partnerType === PartnerType.Store ? props.partnerId : undefined,
                    props.partnerType === PartnerType.Customer ? props.partnerId : undefined,
                    props.carrierId,
                    request.keyword);
            },
        },
        itemTemplate: (item) => (
            <div className="grid order-grid-item">
                <div className="col-12 pb-0">
                    <div className="flex flex-column justify-content-between align-items-start lg:flex-row">
                        <span>
                            <span className="mr-2" style={{ fontWeight: 'bolder', fontSize: '18px' }}>{tawreedTranslationService.translate('lbl_sales_orders_order')}</span>
                            <span className="" style={{ fontWeight: 'bolder', fontSize: '18px' }}>#{item.orderId}</span>
                        </span>
                        <small className="order-date" style={{ fontWeight: 'bolder' }}>{item.orderDate && DateUtils.format(item.orderDate, 'datetime')}</small>
                    </div>
                </div>
                <div className="col-12 pt-2 pb-0 title_order_card">
                    <span className="order-customer">{item.customer?.partnerName}</span>
                </div>
                <div className="col-12 lg:col-6 pt-2 pb-0">
                    <span className="mr-2  value_order_card">{tawreedTranslationService.translate('lbl_sales_orders_total')}:</span>
                    <span className="order-total title_order_card">{ObjectUtils.toFixed(item.total)}</span>
                </div>
                <div className="col-12 lg:col-6 pt-2 pb-0">
                    <span className="mr-2 title_order_card ">{tawreedTranslationService.translate('lbl_sales_orders_items_count_short')}:</span>
                    <span className="order-tota value_order_card ">{item.itemsCount}</span>
                </div>
                {/* <div className="col-12 pt-0">
                    <span className="mr-2">{tawreedTranslationService.translate('lbl_sales_orders_store')}:</span>
                    <span className="order-store">{item.store?.partnerName}</span>
                </div> */}
                <div className="col-12 pt-2">
                    <span className="mr-2 value_order_card">{tawreedTranslationService.translate('lbl_price_scheme_price_list_name')}:</span>
                    <span className="order-store title_order_card">{item.priceListItem?.name}</span>
                </div>
                <div className="col-12 pt-0">
                    <span className="mr-2 value_order_card">{tawreedTranslationService.translate('lbl_sales_orders_paymentmodeid')}:</span>
                    <span className="order-store title_order_card">{constants?.paymentModes.find(el => el.value === item.paymentModeId)?.label}</span>
                </div>
            </div>
        ),
        detailTemplate: (item, onRefresh) => <OrderForm returned={props.returned} orderId={item} listener={onRefreshAsListener(onRefresh)} />,
        filter: {
            filterBy: 'orderId,customer.partnerName',
        },
        toolbar: {
            actions: props.actions,
        },
        selection: {
            mode: props.selectionMode,
            selectAllOnLoad: props.selectionSelectAllOnLoad,
        }
    };
    return (<TawreedDetailView {...tawreed} />);
}
