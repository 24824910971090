import { TawreedRoleName } from "../../../auth/domain";
import { UserRole } from "../models/role";

export const RoleMapper = {

    fromJson(from: any): UserRole {
        if (from) {
            // 'ADMIN' | 'MANAGER' | 'SALES' | 'DELIVERY' | 'STORE_ADMIN' | 'CUSTOMER_ADMIN'
            if (from === 'ADMIN') {
                return UserRole.Administrator;
            } else if (from === 'MANAGER') {
                return UserRole.Manager;
            } else if (from === 'SALES') {
                return UserRole.Sales;
            } else if (from === 'DELIVERY') {
                return UserRole.Delivery;
            } else if (from === 'STORE_ADMIN') {
                return UserRole.Store;
            } else if (from === 'CUSTOMER_ADMIN') {
                return UserRole.Customer;
            }
            else if (from === 'FINANCE') {
                return UserRole.Finance;
            }
            else if (from === 'SYS_ADMIN') {
                return UserRole.SysAdmin;
            }
            else if (from === 'STORE_DELIVERY') {
                return UserRole.StoreDelivery;
            }
            else if (from === 'TOPUP_ADMIN') {
                return UserRole.TopUpAdmin;
            }
            else if (from === 'WALLET_ADMIN') {
                return UserRole.WalletAdmin;
            }
            else if (from === 'CATMANAGER') {
                return UserRole.CatManager;
            }
        }
        return UserRole.Unknown;
    },

    toJson(from: UserRole): TawreedRoleName {
        if (from) {
            // 'ADMIN' | 'MANAGER' | 'SALES' | 'DELIVERY' | 'STORE_ADMIN' | 'CUSTOMER_ADMIN'
            if (from === UserRole.Administrator) {
                return 'ADMIN';
            } else if (from === UserRole.Manager) {
                return 'MANAGER';
            } else if (from === UserRole.Sales) {
                return 'SALES';
            } else if (from === UserRole.Delivery) {
                return 'DELIVERY';
            } else if (from === UserRole.Store) {
                return 'STORE_ADMIN';
            } else if (from === UserRole.Customer) {
                return 'CUSTOMER_ADMIN';
            }
            else if (from === UserRole.Finance) {
                return 'FINANCE';
            }
            else if (from === UserRole.SysAdmin) {
                return 'SYS_ADMIN';
            }
            else if (from === UserRole.StoreDelivery) {
                return 'STORE_DELIVERY';
            }
            else if (from === UserRole.TopUpAdmin) {
                return 'TOPUP_ADMIN';
            }
            else if (from === UserRole.WalletAdmin) {
                return 'WALLET_ADMIN';
            }
            else if (from === UserRole.CatManager) {
                return 'CATMANAGER';
            }

        }
        throw new Error();
    }
}
