import { JObject } from "../../../../common/data/models";
import { ObjectUtils } from "../../../../helpers/object";
import { TawreedConstants } from "../../../constants";
import { DocumentReportItem, ReportResultDto, ReportTableTotalItemDto } from "../../domain";
import { DocumentReportItemMapper } from "./documents-item";
import { UrlsMapper } from "./urls";


export const DocumentReportMapper = {

    fromJson(obj: JObject, constants: TawreedConstants | undefined): ReportResultDto<DocumentReportItem> {

        if (!constants || !constants.documentTypes) {
            throw new Error('constants or constants.documentTypes is undefined');
        }

        const totals = obj.totals;
        const elements = constants.documentTypes;

        const columns = ['lbl_reports_documents_totalcount', 'lbl_reports_documents_totalamount'];
        const rows: string[] = [];
        const value: ReportTableTotalItemDto[] = [];

        if (totals) {
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
    
                rows.push(element.value);
                let values = [];
                if (totals[element.value]) {
                    values = [totals[element.value].totalDocuments, ObjectUtils.toFixed(totals[element.value].totalAmount)];
                } else {
                    values = [0, 0];
                }
                value.push({ name: element.value, label: element.label, values: values });
            }
        }
 
        return {
            urls: obj.reportUrls ? UrlsMapper.fromJson(obj.reportUrls) : undefined,
            data: {
                status: 200,
                error: undefined,
                message: undefined,
                data: {
                    ...obj.page,
                    content: obj.page.content?.map((e: JObject) => DocumentReportItemMapper.fromJson(e))
                }
            },
            totals: {
                columns,
                rows,
                value,
            },
        }
    }
}