import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { CssUtils } from "../../../../../helpers/css";
import { Routes } from "../../../../../router";
import { PaymentPartner, PaymentPartnerService } from "../../../domain";
import { TawreedTableFilters } from "../../../../../common/components/table/filters";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PartnerDto } from "../../../../partners/domain";


export const PaymentPartnerTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new PaymentPartnerService(), []);
    const { constants: { constants } } = React.useContext(GlobalContext);
    const [reload, setReload] = useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.PaymentPartnersNew);
            resolve();
        });
    }

    const onDetails = (sender?: PaymentPartner): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.paymentPartnerId) {
                history.push(Routes.PaymentPartnersDetails.replace(':id', sender.paymentPartnerId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }
    const onActivate = (sender?: PaymentPartner | Array<PaymentPartner>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let partners: PaymentPartner[] | undefined = undefined;
            if (Array.isArray(sender)) {
                partners = sender;
            } else if (sender) {
                partners = [sender];
            }
            if (!partners || !partners.length) {
                reject('No item selected');
            } else {
                setReload(false);
                service.activateAll(partners.map(e=>e.paymentPartnerId!))
                    .then((result:boolean) => {
                        if(result){
                            setReload(true);
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    };

    const onDeactivate = (sender?: PaymentPartner | Array<PaymentPartner>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let partners: PaymentPartner[] | undefined = undefined;
            if (Array.isArray(sender)) {
                partners = sender;
            } else if (sender) {
                partners = [sender];
            }
            if (!partners || !partners.length) {
                reject('No item selected');
            } else {
                setReload(false);
                service.deactivateAll(partners.map(e=>e.paymentPartnerId!))
                    .then(() => {
                        setReload(true);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    };
    const onDelete = (sender?: PaymentPartner | Array<PaymentPartner>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let partners: number[];
            if (!sender) {
                reject('No item selected');
                return;
            } else if (Array.isArray(sender)) {
                 setReload(false);
                 partners = sender.map(e => e.paymentPartnerId!);
            } else {
                 partners = [sender.paymentPartnerId!];
                 setReload(false);
            }
            return service.deleteAll(partners).then(() => setReload(true)).catch(err => console.error(err));
        })
    };

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'paymentPartnerId',
        name: 'tbl.payment.partners',
        title: 'lbl_payment_partners',
        reload: reload,
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "partnerName",
                header: "lbl_partners_partner_name",
                sortable: true,
                sortField: 'partnerName',
                filter: true,
                filterField: 'partnerName',
                style: CssUtils.width('30%'),
            },
            {
                field: "transferCost",
                header: "lbl_payment_partners_transferCost",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('transferCost', undefined, CssUtils.width('25%')),
            },
            {
                field: "transferSelling",
                header: "lbl_payment_partners_transferSelling",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('transferSelling', undefined, CssUtils.width('25%')),
            },
            {
                field: "cashbackPercentage",
                header: "lbl_payment_partners_cashbackPercentage",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('cashbackPercentage', undefined, CssUtils.width('25%')),
            },
            {
                dataType: 'boolean',
                field: "active",
                header: "lbl_partners_active",
                sortable: true,
                sortField: 'active',
                filter: false,
                filterField: 'active',
                ...TawreedTableFilters.boolean('active'),
                style: CssUtils.width('20%'),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.PaymentPartnersCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
            
                new TawreedAction(TawreedActionName.PaymentPartnersDelete, 'statefull', (e) => tawreedTranslationService.translate('lbl_delete_all', e?.length ?? 0), PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
                new TawreedAction(TawreedActionName.PaymentPartnersActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate_all', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-secondary', 'confirm'),
                new TawreedAction(TawreedActionName.PaymentPartnersDeactivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_deactivate_all', e?.length ?? 0), PrimeIcons.CHECK, onDeactivate, 'p-button-secondary', 'confirm'),
            ],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.PaymentPartnersDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'partnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'transferCost': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'transferSelling': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'cashbackPercentage': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'active': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ],
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'paymentPartnerId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}