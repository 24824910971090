import React from "react";
import {TawreedAutoComplete, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText} from "../../../../../common/components/form";
import {OrderItemDto, ReturnedOrderDto, ReturnedOrderService} from "../../domain";
import {PartnerAutoCompleteComponent} from "../../../../partners";
import {PartnerType} from "../../../../partners/data";
import {tawreedTranslationService} from "../../../../../common/translations";
import {ObjectUtils} from "../../../../../helpers/object";
import {Panel} from "primereact/panel";
import {TawreedActionName} from "../../../../../common/actions";
import {PrimeIcons} from "primereact/api";

declare type ReturnedOrderFormState = { loading: boolean; mode: TawreedFormMode; form: ReturnedOrderDto };

export const ReturnedOrderForm: React.FC = (_props) => {
    // di
    const service: ReturnedOrderService = React.useMemo(() => new ReturnedOrderService(), []);

    // states
    const [state, setState] = React.useState<ReturnedOrderFormState>({
        loading: false,
        mode: 'Create',
        form: {},
    });

    const onSubmitExecute = (data: ReturnedOrderDto, cb: TawreedFormSubmitCallback<ReturnedOrderDto>) => {
        setState({...state, loading: true});
        return service.createReturnedOrder(data)
            .then((_) => {
                setState({...state, loading: false, form: {}});
                cb({});
            })
            .catch(err => {
                setState({...state, loading: false});
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<ReturnedOrderDto> = {
        showButton: true,
        resetOnSubmit: true,
        onSubmit: new TawreedFormSubmitAction<ReturnedOrderDto>(TawreedActionName.OrdersReturnCreate, 'lbl_save', PrimeIcons.SAVE, onSubmitExecute)
    }

    return (
        <TawreedForm title="lbl_sales_returned_orders_new" dataKey="orderId" data={state.form} submit={onSubmit} loading={state.loading} mode={state.mode} useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="customer" title="lbl_sales_returned_order_customer" className="field col-12 md:col-6">
                        <PartnerAutoCompleteComponent partnerType={PartnerType.Customer}
                                                      autoCompleteName="customer"
                                                      autoCompleteField="partnerName"
                                                      autoCompleteRules={{required: "msg_field_is_required"}}
                                                      autoCompleteRender="form"
                                                      autoCompleteOnChange={e => {
                                                          setState({...state, form: {...state.form, customer: e.value, item: undefined}});
                                                      }}/>
                    </TawreedFormField>

                    <TawreedFormField name="item" title="lbl_sales_returned_order_order_item" className="field col-12 md:col-6">
                        <TawreedAutoComplete
                            name="item"
                            field="orderItemName"
                            onSearch={(q) => service.searchCustomerItems(state.form.customer?.partnerId, q)}
                            itemTemplate={(rowData: OrderItemDto) => {
                                return <div className="grid">
                                    <div className="col-12 md:col-6 md:pb-0">
                                        <div className="flex">
                                            <span>{rowData.productName}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6 md:pb-0">
                                        <div className="flex">
                                            <span>{rowData.orderId}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="inline-flex align-items-center justify-content-between">
                                            <span className="mr-3">{tawreedTranslationService.translate('lbl_store_products_discount_percent')}:</span>
                                            <span className="mr-auto">{ObjectUtils.toFixed(rowData.discountValue)}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="inline-flex align-items-center justify-content-between">
                                            <span className="mr-3">{tawreedTranslationService.translate('lbl_store_products_retail_price')}:</span>
                                            <span className="mr-auto">{ObjectUtils.toFixed(rowData.retailPrice)}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6 md:pt-0">
                                        <div className="flex">
                                            <span className="mr-3">{tawreedTranslationService.translate('lbl_store_products_store_name')}:</span>
                                            <span>{rowData.storeName}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6 md:pt-0">
                                        <div className="flex">
                                            <span className="mr-3">{tawreedTranslationService.translate('lbl_store_products_customer_name')}:</span>
                                            <span>{rowData.customerName}</span>
                                        </div>
                                    </div>
                                </div>;
                            }}
                            scrollHeight="32em"
                            rules={{required: "msg_field_is_required"}}
                            onSelect={e => {
                                setState({...state, form: {...state.form, item: e.value, customer: state.form.customer ?? {partnerName: e.value.customerName}}});
                            }}
                            render="form"/>
                    </TawreedFormField>

                    <TawreedFormField title="lbl_sales_returned_order_order_id" className="field col-12 md:col-6">
                        <TawreedInputText
                            name="item"
                            nested="orderId"
                            readOnly
                            disabled
                            render="form-nested"/>
                    </TawreedFormField>

                    <TawreedFormField title="lbl_sales_returned_order_store_name" className="field col-12 md:col-6">
                        <TawreedInputText
                            name="item"
                            nested="storeName"
                            readOnly
                            disabled
                            render="form-nested"/>
                    </TawreedFormField>

                    <TawreedFormField title="lbl_sales_returned_order_retail_price" className="field col-12 md:col-6">
                        <TawreedInputNumber
                            name="item"
                            nested="retailPrice"
                            readOnly
                            disabled
                            mode="decimal"
                            render="form-nested"/>
                    </TawreedFormField>

                    <TawreedFormField title="lbl_sales_returned_order_discount_percent" className="field col-12 md:col-6">
                        <TawreedInputNumber
                            name="item"
                            nested="discountPercent"
                            readOnly
                            disabled
                            mode="decimal"
                            render="form-nested"/>
                    </TawreedFormField>

                    <TawreedFormField title="lbl_sales_returned_order_qantity" className="field col-12 md:col-6">
                        <TawreedInputNumber
                            name="item"
                            nested="returnQuantity"
                            readOnly
                            disabled
                            render="form-nested"/>
                    </TawreedFormField>

                    <TawreedFormField name="quantity" title="lbl_sales_returned_order_return_qantity" className="field col-12 md:col-6">
                        <TawreedInputNumber
                            disabled={!state.form || !state.form.item || !state.form.item.orderItemId}
                            name="quantity"
                            rules={{
                                required: "msg_field_is_required",
                                min: {
                                    value: 1,
                                    message: tawreedTranslationService.translate('msg_field_min_allowed_exceeded', '1'),
                                },
                                max: {
                                    value: state.form.item?.returnQuantity!,
                                    message: tawreedTranslationService.translate('msg_field_max_allowed_exceeded', state.form.item?.returnQuantity?.toString() ?? ''),
                                },
                            }}
                            render="form"/>
                    </TawreedFormField>
                </div>
            </Panel>
        </TawreedForm>
    );
}
