import { JObject } from "../../../../common/data/models"
import { User } from "../models"
import { UserDto } from "../../domain";
import { PartnerMapper } from "../../../partners/data";
import { RoleMapper } from "./role";

export const UserMapper = {
    /**
     * Create model from json object
     * @param json
     * @returns {User}
     */
    fromJson: (json: JObject): User => {
        return {
            userId: json['userId'],
            email: json['email'],
            phoneCode: Number.parseInt(json['phoneCode']),
            mobileNo: json['mobileNo'],
            firstName: json['firstName'],
            lastName: json['lastName'],
            fullName: json['fullName'],
            partner: json['partnerId'] ? PartnerMapper.fromJson({ partnerId: json['partnerId'], partnerName: json['partnerName'], partnerType: json['partnerType'] }) : undefined,
            locked: json['locked'],
            prefLangCode: json['prefLangCode'],
            roleCode: RoleMapper.fromJson(json['roleCode']),
            partnerAdmin: json['partnerAdmin'],
            nationality: json?.nationality,
            birthday: json?.birthday ? new Date(json?.birthday) : undefined,
            suspend: json?.suspend
        }
    },

    /**
     *
     */
    toJson: (user: User): JObject => {

        return { ...user, partnerId: user.partner?.partnerId ?? undefined, roleCode: RoleMapper.toJson(user.roleCode) }
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {User}
     */
    fromDto: (dto: UserDto): User => {
        // if (!dto.partner) {
        //     throw new Error('Partner is undefined.');
        // }
        if (!dto.prefLangCode) {
            throw new Error('Language is undefined.');
        }
        else if (!dto.roleCode) {
            throw new Error('Role is undefined.');
        }
        return {
            userId: dto.userId ?? null,
            firstName: dto.firstName,
            lastName: dto.lastName,
            fullName: dto.fullName,
            email: dto.email ?? null,
            phoneCode: dto.phoneCode ?? null,
            mobileNo: dto.mobileNo ?? null,
            partner: dto.partner ? PartnerMapper.fromDto(dto.partner) : undefined,
            locked: dto.locked ?? false,
            prefLangCode: dto.prefLangCode,
            roleCode: dto.roleCode,
            partnerAdmin: dto.partnerAdmin,
            nationality: dto.nationality,
            birthday: dto.birthday,
            suspend: dto.suspend
        }
    },

    /**
     *
     */
    toDto: (model: User): UserDto => {
        return {
            userId: model.userId,
            firstName: model.firstName,
            lastName: model.lastName,
            fullName: model.fullName,
            email: model.email,
            phoneCode: model.phoneCode,
            mobileNo: model.mobileNo,
            partner: model.partner ? PartnerMapper.toDto(model.partner) : undefined,
            locked: model.locked,
            prefLangCode: model.prefLangCode,
            roleCode: model.roleCode,
            partnerAdmin: model.partnerAdmin,
            nationality: model.nationality,
            birthday: model.birthday,
            suspend: model.suspend
        };
    },
}
