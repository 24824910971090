import React, { useEffect } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableFilterParams, DataTableOperatorFilterMetaData, DataTablePageParams, DataTableRowClickEventParams, DataTableSortParams } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { PaginatorTemplate } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import classNames from 'classnames';
import { MultiSelect } from "primereact/multiselect";
import { MenuItem } from "primereact/menuitem";
import { SplitButton } from "primereact/splitbutton";
import { Checkbox } from 'primereact/checkbox';

import { TawreedDataTableFilterMeta, TawreedDataTablePreDefinedFilter, TawreedTableProps } from './context'
import { buildPaginatedRequest, PAGE_SIZE_OPTIONS, PaginatedRequestParams, Result } from '../../pagination';
import { tawreedTranslationService } from '../../translations';
import { TawreedTableEditorEventParams } from "./editors";
import { setFocus, TawreedInputSearch } from "../form";
import { AuthUtils } from "../../../modules/auth/domain";
import { computeTawreedActionTitle, TawreedAction, TawreedActionName } from "../../actions";
import { tawreedTableCache } from "./state";
import { ObjectUtils } from '../../../helpers/object';
import { TawreedInputSearchAsync } from '../form/controls/input-async';

import './table.scss';

export const TawreedTable: React.FC<TawreedTableProps> = (props) => {

    const table = React.useRef<DataTable>(null);
    const cache = tawreedTableCache.restore(props.name) ?? {
        params: {
            sort: props.sort?.initial,
            filters: props.filter?.initial,
            keyword:props.keyword||undefined
        },
        filters: [],
    };
    const [params, setParams] = React.useState<PaginatedRequestParams>(buildPaginatedRequest(cache.params));
    const [loading, setLoading] = React.useState<boolean>(false);
    const [asyncSearchLoading, setAsyncSearchLoading] = React.useState<boolean>(false);
    const [totalElements, setTotalElements] = React.useState<number>(0);
    const [result, setResult] = React.useState<Result<any>>();
    const [data, setData] = React.useState<Array<any>>([]);
    const [filters, setFilters] = React.useState<Array<TawreedDataTableFilterMeta>>(cache.filters);
    const [status, setStatus] = React.useState<'clean' | 'dirty'>('dirty');

    const [selected, setSelected] = React.useState<any>(null);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            load();
        }
        return () => {
            mounted = false;
        };
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        let mounted = true;
        if (mounted && props.ds && props.ds.mode === 'basic') {
            load();
        } else if (props.reload) {
            load();
        }
        return () => {
            mounted = false;
        };
    }, [props.ds, props.reload]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (!props.pagination) {
                setParams(previous => { return { ...previous, size: ObjectUtils.MAX_INT, page: 0 } });
            }
        }
        return () => { mounted = false; };

    }, [props.pagination]);


    function load(): void {
        if (props.ds.mode === 'lazy') {
            if (props.ds.asyncSearch) {
                setAsyncSearchLoading(true);
            }
            else {
                setLoading(true);
            }
            props.ds.onSearch(params)
                .then(data => {
                    setLoading(false);
                    setAsyncSearchLoading(false);
                    if (data.data) {
                        setTotalElements(data.data.totalElements ?? 0);
                        setResult(data.data);
                        setData(data.data.content);
                        if (props.selection && props.selection.selectAllOnLoad) {
                            setSelected(data.data.content);
                        }
                        if (status === 'clean') {
                            setFocus('tawreedTableGlobalSearch');
                        }
                        if(props.name!="")
                        {
                            tawreedTableCache.save(props.name, { params, filters });
                        }
                    }
                })
                .catch(() => {
                    setLoading(false);
                    setAsyncSearchLoading(false);
                });
        } else {
            setTotalElements(props.ds.data.length);

            const ee = props.ds.data.map(e => { return { ...e } });
            setData(ee);
            if (props.selection && props.selection.selectAllOnLoad) {
                setSelected(ee);
            }
        }
    }

    function onPage(e: DataTablePageParams): void {
        if (e.page != null && e.page !== undefined) {
            setStatus('dirty');
            setParams({ ...params, page: e.page });
        }
    }

    function onSort(e: DataTableSortParams) {
        setStatus('dirty');
        setParams({ ...params, sort: e });
    }

    function onFilter(e: DataTableFilterParams) {
        const ff: TawreedDataTableFilterMeta = {};
        for (let k in e.filters) {
            if (e.filters[k].hasOwnProperty('constraints')) {
                ff[k] = e.filters[k] as DataTableOperatorFilterMetaData;
            }
        }
        setStatus('dirty');
        setParams({ ...params, filters: ff });
    }

    function onClearFilters() {
        setStatus('dirty');
        setFilters([]);
        setParams({ ...params, filters: props.filter ? props.filter.initial : {}, keyword: undefined });
    }

    function onGlobalFilter(e?: string) {
        if (e !== params.keyword) {
            setStatus('clean');
            setParams({ ...params, keyword: e });
        }
    }

    function onRowClick(e: DataTableRowClickEventParams): void {
        const target = e.originalEvent.target as HTMLElement;
        if (target && !target.classList.contains('p-component')) {
            if (props.row && props.row.onClick && AuthUtils.current().authorized(props.row.onClick.name)) {
                props.row.onClick.execute(e.data).then();
            }
        }
    }

    async function onEditComplete(e: TawreedTableEditorEventParams): Promise<void> {
        if (!props.editable) {
            return;
        }
        const { field, newRowData, newValue, completed } = e;
        setRowData(newRowData);
        if (completed) {
            await props.editable.onEditComplete(newRowData, field, newValue);
        }
    }

    function isCorrectSelection(e: any): boolean {
        if (e != null && e !== undefined) {
            if (Array.isArray(e) && !e.length) {
                return false;
            }
            if (props?.selection?.isCorrectSelect)
                return props?.selection?.isCorrectSelect(e);
            else
                return true;
        }
        return false;
    }

    function setRowData(newData: any): void {
        if (data && data.length) {
            const content: any[] = data;
            const index = content.findIndex(x => x[props.dataKey] === newData[props.dataKey]);
            if (index < 0 || index >= content.length) {
                throw new Error("INDEX_OUT_OF_BOUNDS");
            }
            content[index] = newData;
            setResult({ ...result!, content: content });
            setData(content);
        }
    }

    const onExecute = (e: TawreedAction, a: boolean) => {
        if (e.name === TawreedActionName.ToggleSelection) {

            if (selected) {
                setSelected(null);
            } else {
                setSelected(data);
            }

        } else {
            e.execute(selected).then(() => {
                if (a) {
                    const trigger = props.ds.trigger;
                    if (trigger === 'normal_and_action') {
                        load();
                    }
                }
            });
        }
    }

    const TawreedTableToolbarSplitButton: React.FC<{ actions: TawreedAction[] }> = ({ actions }) => {


        if (!actions || !actions.length) {
            return <React.Fragment></React.Fragment>
        }
        else if (actions.length === 1) {
            const e = actions[0];
            return <Button label={tawreedTranslationService.translate(computeTawreedActionTitle(selected, e.title))} icon={e.icon} className={e.className} onClick={() => onExecute(e, false)} disabled={loading || props.outLoader} />
        } else {

            const back = actions.find(e => e.name === TawreedActionName.Back);
            const first = actions.find(e => e.name !== TawreedActionName.Back);
            const items: MenuItem[] = [];

            for (let i = 0; i < actions.length; i++) {
                if (actions[i] !== back && actions[i] !== first) {
                    items.push({
                        label: tawreedTranslationService.translate(computeTawreedActionTitle(selected, actions[i].title)),
                        icon: actions[i].icon,
                        command: () => onExecute(actions[i], false),
                        disabled: loading || (actions[i].type === 'statefull' && !isCorrectSelection(selected)) || props.outLoader,
                    });
                }
            }

            return (
                <React.Fragment>
                    {
                        back &&
                        <Button label={tawreedTranslationService.translate(computeTawreedActionTitle(selected, back.title))} icon={back.icon} className={'p-button-sm ' + back.className} onClick={() => back && onExecute(back, false)} disabled={loading || (back.type === 'statefull' && !isCorrectSelection(selected)) || props.outLoader} />
                    }
                    {
                        first &&
                        <SplitButton className="p-button-sm" label={tawreedTranslationService.translate(computeTawreedActionTitle(selected, first.title))} icon={first.icon} onClick={() => first && onExecute(first, first.type === 'statefull')} disabled={loading || (first.type === 'statefull' && !isCorrectSelection(selected)) || props.outLoader} model={items} />
                    }
                </React.Fragment>
            )
        }
    }

    const TawreedTableToolbarGroupButton: React.FC<{ actions: TawreedAction[] }> = ({ actions }) => {

        if (!actions || !actions.length) {
            return <React.Fragment></React.Fragment>
        }
        return (
            <React.Fragment>
                {
                    actions.map((e, index) =>
                        <Button key={index}
                            label={tawreedTranslationService.translate(computeTawreedActionTitle(selected, e.title))}
                            icon={e.icon}
                            className={e.className}
                            disabled={loading || (e.type === 'statefull' && !isCorrectSelection(selected)) || props.outLoader}
                            onClick={() => onExecute(e, true)} />)
                }
            </React.Fragment>
        )
    }


    // Toolbar
    const TawreedTableToolbar: React.FC = () => {

        let actions: TawreedAction[] = [];
        if (props.toolbar && props.toolbar.actions && props.toolbar.actions.length) {
            actions.push(...props.toolbar.actions.filter(e => AuthUtils.current().authorized(e.name)));
        }
        if (props.canImport && AuthUtils.current().authorized(props.canImport.name)) {
            actions.push(props.canImport);
        }
        if (props.canExport) {
        }
        if (!actions || !actions.length) {
            return <React.Fragment />;
        }

        const toolbar = (
            <React.Fragment>
                <div className="flex align-items-center md:hidden">
                    <TawreedTableToolbarSplitButton actions={actions} />
                </div>
                <div className="hidden md:flex">
                    <TawreedTableToolbarGroupButton actions={actions} />
                </div>
            </React.Fragment>
        );
        return <Toolbar className="tawreed-table-toolbar mb-4" left={toolbar} />


    }

    // GlobalSearchField
    const GlobalSearchField: React.FC = () => {
        return (
            <React.Fragment>
                {props.ds && props.ds.mode === 'lazy' && props.ds.asyncSearch ?
                    <TawreedInputSearchAsync className="flex-grow-1 md:flex-grow-0 m-2" name="tawreedTableGlobalSearch" type="search" placeholder={tawreedTranslationService.translate("lbl_search")} value={params.keyword}
                        onStoping={(e: string) => onGlobalFilter(e)} autoComplete="off" loading={asyncSearchLoading} />
                    :
                    <TawreedInputSearch className="flex-grow-1 md:flex-grow-0 m-2" name="tawreedTableGlobalSearch" type="search" placeholder={tawreedTranslationService.translate("lbl_search")} value={params.keyword}
                        onEnterPressed={(e: string) => onGlobalFilter(e)} autoComplete="off" />}
                {
                    props.filter &&
                    props.filter.pre &&
                    <MultiSelect showSelectAll={false}
                        maxSelectedLabels={1}
                        style={{ minWidth: '125px' }}
                        className="flex-grow-1 md:flex-grow-0 m-2 md:mr-auto"
                        placeholder={tawreedTranslationService.translate('lbl_all')}
                        value={filters}
                        itemTemplate={(e) => tawreedTranslationService.translate(e.name)}
                        selectedItemTemplate={(e) => e && e.name ? tawreedTranslationService.translate(e.name) : e}
                        selectedItemsLabel={tawreedTranslationService.translate('lbl_items_selected', filters?.length.toString() ?? '')}
                        options={props.filter.pre}
                        optionLabel="name"
                        onChange={(e) => {
                            if (props.filter && props.filter.pre) {
                                let newFilters: TawreedDataTableFilterMeta = { ...params.filters };

                                props.filter.pre.forEach(pre => {
                                    Object.keys(pre.meta).forEach(key => {
                                        if (newFilters[key]) {
                                            newFilters[key].constraints = [];
                                        }
                                    });
                                });
                                Array.from<TawreedDataTablePreDefinedFilter>(e.value).forEach(filter => {
                                    Object.keys(filter.meta).forEach(key => {
                                        newFilters[key].constraints.push(...filter.meta[key].constraints);
                                    });
                                });

                                setFilters(e.value);
                                onFilter({ filters: newFilters });
                            }
                        }
                        } />
                }
            </React.Fragment>
        )
    }

    // Header
    const Header: React.FC = () => {
        if (!props.header) {
            return <React.Fragment />
        }
        const allSelected = selected && data && selected.length === data.length;
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="flex flex-wrap align-items-center justify-content-start">
                        {!props.disableGlobalSearch && props.header && (typeof props.header !== 'object' || (props.header.left && props.header.left.search)) && <GlobalSearchField />}
                        {props.header && typeof props.header === 'object' && props.header.left && props.header.left.custom}
                        {
                            (props.selection && props.selection.mode === 'checkbox') &&
                            <div className="md:hidden m-2">
                                <Checkbox checked={allSelected} onChange={e => { setSelected(!e.checked ? [] : data) }} className="mr-2" />
                                <label>{tawreedTranslationService.translate('lbl_toggle_selection')}</label>
                            </div>
                        }

                        <span className="m-2 ml-auto">
                            {!props.disableGlobalSearch && props.header && (typeof props.header !== 'object' || (props.header.right && props.header.right.clear)) &&
                                <Button type="button" icon="pi pi-filter-slash" label={tawreedTranslationService.translate('lbl_clear_filters')} className="p-button-outlined flex-grow-1 md:flex-grow-0 mr-2 hidden md:block" onClick={() => onClearFilters()} />}
                            {props.header && typeof props.header === 'object' && props.header.right && props.header.right.custom}
                        </span>
                    </div>
                </div>
                {
                    props.header && typeof props.header === 'object' && props.header.bottom && <div className="col-12">{props.header.bottom}</div>
                }
            </div>
        );
    }
    useEffect(() => {
        if (selected && props.selection?.customeAction)
            props.selection?.customeAction(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    // Paginator Template
    const paginatorTemplate: PaginatorTemplate = {
        layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-left" />
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-right" />
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = PAGE_SIZE_OPTIONS.map(e => {
                return { label: e, value: e }
            });

            return (
                <React.Fragment>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => {
                        options.onChange(e);
                        setStatus('dirty');
                        setParams({ ...params, size: e.value });
                    }} appendTo={document.body} />
                </React.Fragment>);
        },
        'FirstPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-double-left" />
                    <Ripple />
                </button>
            )
        },
        'LastPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <i className="pi pi-angle-double-right" />
                    <Ripple />
                </button>
            )
        },
        'CurrentPageReport': (options) => {
            return <React.Fragment>
                <div className={options.className}>
                    {options.first} - {options.last} {tawreedTranslationService.translate('lbl_paginator_curr_of')} {options.totalRecords}
                </div>
            </React.Fragment>
        },
        'JumpToPageInput': (options) => {
            return <div>Jump to {options.element}</div>;
        },
    };

    const TableRowCallables: React.FC<{ rowData: any }> = ({ rowData }) => {

        if (!props.row || !props.row.column) {
            return <React.Fragment />;
        }
        let aa: TawreedAction[] = [];
        if (Array.isArray(props.row.column)) {
            aa = props.row.column;
        } else {
            aa = props.row.column(rowData);
        }
        aa = aa ?? [];
        aa = aa.filter(e => AuthUtils.current().authorized(e.name));

        if (!aa || !aa.length) {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                {
                    aa.map((e, index) =>
                        <button key={index}
                            type="button"
                            title={tawreedTranslationService.translate(computeTawreedActionTitle(rowData, e.title))}
                            className={'p-row-editor-init p-link' + e.className}
                            disabled={loading || (e.type === 'statefull' && !rowData) || props.outLoader}
                            onClick={() => e.execute(rowData)}>
                            <i className={e.icon} />
                        </button>)
                }
            </React.Fragment>
        );
    };

    return (
        <div className="tawreed-table">
            {props.title && <h4>{tawreedTranslationService.translate(props.title)}</h4>}

            {props.toolbar && <TawreedTableToolbar />}

            <DataTable
                ref={table}
                //
                responsiveLayout="stack" breakpoint="960px" header={props.header ? <Header /> : undefined}
                // data
                dataKey={props.dataKey} value={data} loading={loading || props.outLoader} lazy={props.ds.mode === 'lazy'}
                //
                onRowClick={onRowClick}
                // sort
                sortMode={props.sort ? props.sort.sortMode : undefined} sortField={params.sort?.sortField} sortOrder={params.sort?.sortOrder} multiSortMeta={params.sort?.multiSortMeta} removableSort onSort={onSort}
                // filter
                filters={params.filters} filterDisplay={props.filter ? props.filter.filterDisplay : undefined} onFilter={onFilter}
                // selection
                selectionMode={props.selection?.mode} selection={selected} onSelectionChange={e => setSelected(e.value ?? [])}
                // pagination
                {...props.pagination ? {
                    onPage: onPage,
                    first: result?.pageable?.offset,
                    rows: params.size,
                    rowsPerPageOptions: PAGE_SIZE_OPTIONS,
                    totalRecords: totalElements,
                    paginator: true,
                    paginatorTemplate: paginatorTemplate,
                } : {}}
                // edit
                editMode={undefined} onRowEditComplete={undefined}>
                {
                    (props.selection && props.selection.mode === 'checkbox') && <Column selectionMode="multiple" className="selection-cell" exportable={false} />
                }
                {
                    (props.selection && props.selection.mode === 'radiobutton') && <Column selectionMode="single" className="selection-cell" exportable={false} />
                }
                {
                    props.columns.map((column, index) => <Column key={index}
                        {...column}
                        showFilterOperator={false}
                        body={column.body ? (rowData, options) => column.body && column.body(rowData, options, onEditComplete) : undefined}
                        header={tawreedTranslationService.translate(column.header?.toString())} />)
                }
                {
                    props.row && props.row.column && props.row.column.length &&
                    <Column
                        body={(options) => <TableRowCallables rowData={options} />} />
                }
            </DataTable>
        </div>
    );
}
