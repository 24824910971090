import { PaginatedRequestParams, PaginatedResponse, Result, buildSortParamsToString, buildFilterMetaToObject } from "../../../../../common/pagination";
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { StoreProductMapper } from "../../data";
import { StoreProductDto, StoreProductSearchSimilarQuery } from "../../domain";
import { JObject } from "../../../../../common/data/models";

export class StoreProductsRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreProductDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.STORE_PRODUCTS_SAERCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => StoreProductMapper.toSellerDto(StoreProductMapper.fromJson(e))) ?? [] },
                };
            });
    }
    public searchSimilar(q: StoreProductSearchSimilarQuery): Promise<PaginatedResponse<StoreProductDto>> {
        const params = {
            sort: undefined,
            page: 0,
            size: 25,
        };
        //const data = { productName: q.q, zoneId: q.zoneId, businessType: q.actor.businessType };
        const data = { productName: q.q };//, zoneId: q.zoneId, businessType: q.actor.businessType };
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.STORE_PRODUCTS_SAERCH_SIMILAR, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => StoreProductMapper.toDto(StoreProductMapper.fromJson(e))) ?? [] },
                };
            });
    }
    public searchSimilarWithPrice(q: StoreProductSearchSimilarQuery, pagination: PaginatedRequestParams): Promise<PaginatedResponse<StoreProductDto>> {
        const params = {
            sort: pagination.sort ? buildSortParamsToString(pagination.sort) : undefined,
            page: pagination.page,
            size: pagination.size,
        };
        const data = { productName: q.q, zoneId: q.zoneId, businessType: q.actor.businessType, priceListId: q.priceListId };
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.STORE_PRODUCTS_SAERCH_SIMILAR_PRICE, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => StoreProductMapper.toDto(StoreProductMapper.fromJson(e))) ?? [] },
                };
            });
    }

    public createOrUpdate(form: StoreProductDto): Promise<StoreProductDto> {
        const data = StoreProductMapper.toJson(StoreProductMapper.fromDto(form));
        return tawreedHttpService.post<JObject, JObject>(endpoints.STORE_PRODUCTS_CREATE_OR_SAVE, data).then(res => StoreProductMapper.toDto(StoreProductMapper.fromJson(res.data!)));
    }
    public updateQuantity(data: JObject): Promise<JObject> {

        return tawreedHttpService.post<JObject, JObject>(endpoints.STORE_PRODUCTS_UPDATE_QUANTITY, data).then(res => ({}));
    }

    public getDetails(id: number): Promise<StoreProductDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.STORE_PRODUCTS_GET, id, undefined, undefined, 'error').then(res => StoreProductMapper.toDto(StoreProductMapper.fromJson(res.data!)));
    }

    public delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.STORE_PRODUCTS_DELETE, id).then(res => res.status === 200);
    }

    public deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.STORE_PRODUCTS_DELETE_ALL, ids).then(res => res.status === 200);
    }

    public activateAll(products: StoreProductDto[]): Promise<boolean> {
        const ids = products.map(e => e.storeProductId!);
        return tawreedHttpService.post<number[], number>(endpoints.STORE_PRODUCTS_ACTIVATE_ALL, ids).then(res => {
            return res.status === 200
        });
    }

    public deactivateAll(products: StoreProductDto[]): Promise<boolean> {
        const ids = products.map(e => e.storeProductId!);
        return tawreedHttpService.post<number[], number>(endpoints.STORE_PRODUCTS_DEACTIVATE_ALL, ids).then(res => {
            return res.status === 200
        });
    }

}
