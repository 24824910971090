import classNames from 'classnames';
import React from 'react';
import {InputNumber, InputNumberProps} from "primereact/inputnumber";
import {Controller, useFormContext} from "react-hook-form";

import {TawreedControlProps, CONTROL_DEFAULT_CLASSNAME} from '../control';
import {ObjectUtils} from "../../../../../helpers/object";

export type TawreedInputNumberProps = InputNumberProps & TawreedControlProps & { mode?: 'decimal' };

export const TawreedInputNumber: React.FC<TawreedInputNumberProps> = (props) => {

    const form = useFormContext();

    if (!form || !form.control || !props.name || props.render === 'standalone') {
        return <InputNumber {...props} id={props.name} className={classNames(CONTROL_DEFAULT_CLASSNAME)} maxFractionDigits={props.mode === 'decimal' ? (props.maxFractionDigits ?? 2) : 0} minFractionDigits={props.mode === 'decimal' ? (props.minFractionDigits ?? 2) : 0}/>
    }
    if (props.render === 'form-nested') {
        return <Controller name={props.name} rules={props.rules} render={({field, fieldState}) => {
            return <InputNumber
                {...field}
                {...props}
                inputRef={field.ref}
                inputId={field.value + '.' + props.nested}
                value={ObjectUtils.getNested(field.value, props.nested)}
                maxFractionDigits={props.mode === 'decimal' ? (props.maxFractionDigits ?? 2) : 0}
                minFractionDigits={props.mode === 'decimal' ? (props.minFractionDigits ?? 2) : 0}
                className={classNames(CONTROL_DEFAULT_CLASSNAME, {'p-invalid': fieldState.invalid})}
                onChange={e => field.onChange(e.value)}
                onValueChange={(e) => field.onChange(e.value)}/>
        }}/>;
    }
    return (<Controller name={props.name} rules={props.rules} control={form.control}
                        render={({field, fieldState}) => {
                            return <InputNumber
                                {...props}
                                {...field}
                                inputRef={field.ref}
                                inputId={props.name}
                                value={props.value||field.value}
                                maxFractionDigits={props.mode === 'decimal' ? (props.maxFractionDigits ?? 2) : 0}
                                minFractionDigits={props.mode === 'decimal' ? (props.minFractionDigits ?? 2) : 0}
                                className={classNames(CONTROL_DEFAULT_CLASSNAME, {'p-invalid': fieldState.invalid})}
                                onChange={(e) => {
                                    field.onChange(e.value)
                                    props.onChange?.(e)

                                }}
                                onValueChange={(e) => {
                                    field.onChange(e.value)
                                }}
                            />

                        }}/>);
}
