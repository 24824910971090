import React, { FC } from 'react'
import { PartnerType } from '../../data';
import { PartnerForm } from '../components/partner-form';
import { PartnersList } from '../components/partners';

/**
 * Tawreed Customers Page
 * @returns 
 */
export const CustomersPage: FC = () => (<PartnersList type={PartnerType.Customer} />);

/**
 * Tawreed  Customer Form Page
 * @returns 
 */
export const CustomerFormPage: FC = () => (<PartnerForm type={PartnerType.Customer} />);

