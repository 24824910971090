import React from "react";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { OrderItemDto } from "../../domain";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedActionName } from "../../../../../common/actions";
import { AuthUtils } from "../../../../auth/domain";
import { GlobalContext } from "../../../../../context";
import { IBonusType } from "../../../../constants";

export type OrderFormItemsProps = {
    // controls
    className: string;
    disabled: boolean;
    // data
    showEdit?: boolean;
    items?: Array<OrderItemDto>;
    showNote: boolean
    // actions
    onSave: (item: OrderItemDto) => Promise<boolean>;

    returned: boolean | undefined;
};

export const OrderFormItems: React.FC<OrderFormItemsProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);

    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.items ?? [],
        },
        name: 'tbl.sales.orders.order.items',
        dataKey: 'orderItemId',
        columns: props.returned ?
            [
                {
                    dataType: 'text',
                    field: "productName",
                    header: "lbl_sales_orders_o_info_items_product_name",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_sales_orders_o_info_items_quantity",
                    body: props.showEdit ? TawreedTableEditor.numeric('int', 'quantityOrdered', 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditQuantity)) : undefined,
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "retailPrice",
                    header: "lbl_sales_orders_o_info_items_retail_price",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "discountPercent",
                    header: "lbl_sales_orders_o_info_items_discount_percent",
                    style: CssUtils.width('20%'),
                },
                {
                    field: "itemTotal",
                    header: "lbl_sales_orders_o_info_items_item_total",
                    ...TawreedTableFormatters.decimal("itemTotal", CssUtils.width('20%')),
                },
            ]
            :
            [
                {
                    dataType: 'text',
                    field: "productName",
                    header: "lbl_sales_orders_o_info_items_product_name",
                    style: CssUtils.width('30%'),
                },
                {
                    dataType: 'numeric',
                    field: "quantityOrdered",
                    header: "lbl_sales_orders_o_info_items_quantity_ordered",
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_sales_orders_o_info_items_quantity",
                    body: props.showEdit ? TawreedTableEditor.numeric('int', 'quantityOrdered', 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditQuantity), "60px") : undefined,
                    style: CssUtils.width('10%'),
                },
                {
                    field: "retailPrice",
                    header: "lbl_sales_cart_store_cart_items_retail_price",
                    ...TawreedTableFormatters.decimal("retailPrice"),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "discountValue",
                    header: "lbl_base_discount",
                    ...TawreedTableFormatters.currencyOrPercentageDepend("discountValue", 'discountType', constants?.info?.currencyCode ?? ''),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    dataType: 'numeric',
                    field: "discountBonus",
                    header: "lbl_additional_discount",
                    body: props.showEdit ? TawreedTableEditor.numeric('decimal', undefined, 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditBonus), "60px") : undefined,
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: "lbl_store_products_bonus",
                    body: props.showEdit ? TawreedTableEditor.numeric('int', undefined, 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditBonus), "60px") : undefined,
                    style: CssUtils.width('10%'),
                },
                {
                    field: "tax",
                    header: "lbl_salesordersitem_totaltax",
                    ...TawreedTableFormatters.percentage("storeProductTax"),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "itemTotal",
                    header: "lbl_sales_orders_o_info_items_item_total",
                    ...TawreedTableFormatters.decimal("itemTotal"),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
            ],
        editable: props.showEdit ? { onEditComplete: (newRowData, _field, _value) => props.onSave(newRowData) } : undefined,
        header: {
            left: {
                search: false,
                custom: (
                    <React.Fragment>
                        <span>
                            {tawreedTranslationService.translate('lbl_sales_orders_o_info_items')}
                        </span>
                        {props.showNote && <span style={{ fontSize: '13px', fontWeight: 'normal', padding: '4px' }}>
                            {tawreedTranslationService.translate('lbl_placed_note')}
                        </span>}
                    </React.Fragment>

                )
            },
            // right: {
            //     clear: false,
            //     custom: (
            //         <div className="formgroup-inline">
            //             <TawreedFormField title="lbl_sales_orders_items_count_short" className="field mb-0 w-full md:w-auto">
            //                 <TawreedInputPlain render="form" name="itemsCount" className="inline"/>
            //             </TawreedFormField>
            //         </div>
            //     )
            // }
        }
    };

    return (<div className={props.className}><TawreedTable {...tawreed} /></div>);
}
