import {MessagesRepository} from "../../data";
import {TawreedMessageDto} from "../dtos";
import {Result} from "../../../../common/pagination";

export type MessagesCountCallback = (count: number) => void;
export type MessagesCountUnsubscribe = () => void;

class MessagesService {

    private readonly repository = new MessagesRepository();
    private callbacks: Array<MessagesCountCallback> = [];
    private count = -1;

    public setCount(value: number): void {
        this.count = value;
        this.callbacks.forEach(cb => cb(this.count));
    }

    public getCount(cb: MessagesCountCallback): MessagesCountUnsubscribe {
        if (this.count === -1) {
            this.repository.getCount().then(res => this.setCount(res));
        }
        cb(this.count);
        const index = this.callbacks.length;
        this.callbacks.push(cb);
        return () => {
            this.callbacks.splice(index, 1);
        }
    }

    public async getMessages(page: number = 0): Promise<Result<TawreedMessageDto>> {
        if (!page) {
            page = 0;
        }
        if (page === 0) {
            this.setCount(0);
        }
        return this.repository.getMessages(page);
    }

    public markAsRead(message: TawreedMessageDto): Promise<TawreedMessageDto> {
        if (!message) {
            return Promise.reject();
        }
        return this.repository.markAsRead(message);
    }
}

export const messagesService = new MessagesService();
