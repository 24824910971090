import React,{FC,useMemo, useRef,useState} from 'react';
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import { AuthUtils } from "../../../../auth/domain";
import { PrimeIcons } from "primereact/api";
import { PartnerDto } from "../../../../partners/domain";
import { SalesOfferDetailsDialog } from "./sales-offer-details-dialog";
import { CartService, salesOfferDto } from '../../domain';


export interface CarFormSalesOfferProps {
    className?: string;
    onSelect: (p: salesOfferDto) => void;
    disabled: boolean;
    zoneId: number;
    actor: PartnerDto;
}
export interface CarFormSalesOfferState {
    isVisible: boolean;
    saleOfferId?: number;
    saleOfferTitle?: string;
    expireDate?: string;
    description?: string;
    storeName?:string;
}

export const CarFormSalesOffer: FC<CarFormSalesOfferProps> = (props)=>{
    const service: CartService = useMemo(() => new CartService(), []);
    const ref = useRef<any>(null);

    const [state, setState] = useState<CarFormSalesOfferState>({
        isVisible: false,
        saleOfferId: undefined,
        saleOfferTitle: undefined,
        expireDate: undefined,
        description: undefined,
        storeName:undefined
    });

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        ds: {
            mode: 'lazy',
            asyncSearch: true,
            onSearch: (q) => service.searchSalesoffer({ keyword: q.keyword ?? '',zoneId:props.zoneId}, q).then((res) => {
                console.log(res);
                return res;
            })
        },
        reload: false,
        dataKey: 'salesOfferId',
        name: 'tbl.sales.offer',
        columns: [
            {
                field: "title",
                header: "lbl_sales_offer_title",
                sortField: 'title',
                filter: false,
                filterField: 'title',
                style: CssUtils.width('20%'),
            },
            {
                field: "storeName",
                header: "lbl_sales_offer_store_name",
                sortField: 'storeName',
                filter: false,
                filterField: 'storeName',
                style: CssUtils.width('20%'),
            },
            {
                field: "description",
                header: "lbl_sales_offer_description",
                sortField: 'description',
                filter: false,
                filterField: 'description',
                style: CssUtils.width('20%'),
            },
            {
                field: "expirationDate",
                header: "lbl_sales_offer_expire_date",
                sortField: 'expirationDate',
                filter: false,
                filterField: 'expirationDate',
                style: CssUtils.width('10%'),
            },
            {
                field: "priceListName",
                header: "lbl_sales_offer_price_list",
                sortField: 'priceListName',
                filter: false,
                filterField: 'priceListName',
                style: CssUtils.width('20%'),
            },
            {
                field: "actions",
                body: (options: any) => {
                    return <div><Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.SHOPPING_CART}
                        className="p-button-success"
                        onClick={() => {
                            props.onSelect(options);
                            setState({
                                isVisible: true,
                                saleOfferId: options?.salesOfferId,
                                saleOfferTitle: options?.title,
                                expireDate: options?.expirationDate,
                                description: options?.description,
                                storeName: options?.storeName
                            });
                            console.log(state);
                        }} />
                        </div>
                },
                style: CssUtils.width('12%'),
            }

        ],

        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'title',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },


    }
    return(
        <React.Fragment>
            <TawreedTable {...tawreed} />

            <div style={{ display: 'none' }}>
                <Button type="submit"
                    className="w-full"
                    ref={ref}
                    disabled={props.disabled}
                    label={tawreedTranslationService.translate('lbl_add_to_cart')} />
            </div>
            {
                state.isVisible &&
                state.saleOfferId &&
                <SalesOfferDetailsDialog
                    visible={state.isVisible}
                    salesOfferId={state.saleOfferId}
                    salesOfferTitle={state.saleOfferTitle}
                    salesOfferDescription={state.description}
                    expirationDate={state.expireDate}
                    storeName={state.storeName}
                    hide={() => { setState({ saleOfferId: undefined, isVisible: false }) }}
                    accept={() => {
                        if (ref.current && ref.current instanceof HTMLButtonElement) {
                            ref.current.click();
                            setState({ saleOfferId: undefined, isVisible: false });
                        } else {
                            console.warn('ref.current && ref.current instanceof HTMLButtonElement');
                        }
                    }}
                ></SalesOfferDetailsDialog>}
        </React.Fragment>
    );
}