import React from 'react'
import { TawreedReportGenerator } from './report-generator'
import { TawreedCalendar, TawreedDropdown, TawreedFormField, TawreedInputText } from "../../../../common/components/form";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { GlobalContext } from "../../../../context";
import { PartnerType } from "../../../partners/data";
import { DateUtils } from "../../../../helpers/date";

export type ReportSalesOrdersProps = { returned: boolean; }

/**
 *
 * @constructor
 */
export const ReportSalesOrders: React.FC<ReportSalesOrdersProps> = (props) => {
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);


    return (
        <TawreedReportGenerator name={props.returned ? 'returned-orders' : 'orders'} title={props.returned ? 'lbl_reports_sales_returned_orders' : 'lbl_reports_sales_orders'} initial={{ startDate: DateUtils.now(), endDate: DateUtils.now() }}>
            <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>
            <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
            </TawreedFormField>
            {
                (user?.roleCode !== 'STORE_ADMIN') && (user?.roleCode !== 'STORE_DELIVERY') &&
                <TawreedFormField name="store" title="lbl_reports_sales_orders_store" className="field col-12 md:col-6">
                    <PartnerAutoCompleteComponent partnerType={PartnerType.Store} autoCompleteName="store" autoCompleteField="partnerName" autoCompleteRender="form" />
                </TawreedFormField>
            }
            {
                (user?.roleCode !== 'CUSTOMER_ADMIN') &&
                <TawreedFormField name="customer" title="lbl_reports_sales_orders_customer" className="field col-12 md:col-6">
                    <PartnerAutoCompleteComponent partnerType={PartnerType.Customer} autoCompleteName="customer" autoCompleteField="partnerName" autoCompleteRender="form" />
                </TawreedFormField>
            }
            <TawreedFormField name="orderId" title="lbl_reports_sales_orders_order" className="field col-12 md:col-6">
                <TawreedInputText render="form" name="orderId" />
                {/*<OrderAutoCompleteComponent returned={props.returned} autoCompleteName="order" autoCompleteRender="form"/>*/}
            </TawreedFormField>
            <TawreedFormField name="statusId" title="lbl_reports_sales_orders_status_id" className="field col-12 md:col-6">
                <TawreedDropdown render="form" name="statusId" options={constants?.orderStatuses ?? []} optionLabel="label" optionValue="value" />
            </TawreedFormField>
        </TawreedReportGenerator>
    )
}
