import React from "react";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem/menuitem";
import { PrimeIcons } from "primereact/api";
import { GlobalContext } from "../../../../context";
import { tawreedTranslationService } from "../../../../common/translations";
import { useHistory } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { AuthActions } from "../../domain";
import { Routes } from "../../../../router";

export type TawreedUserDropdownProps = {
    /**
     * ClassName
     */
    className?: string;

    onClick?: (e: React.MouseEvent) => void;
}

export const TawreedUserDropdown: React.FC<TawreedUserDropdownProps> = (props) => {

    // di
    const history = useHistory();
    const { auth: { user, loading }, authDispatch, constantsDispatch } = React.useContext(GlobalContext);

    const menu = React.useRef<Menu>(null);
    const items: Array<MenuItem> = [
        {
            label: user?.fullName,
            command: () => {
                history.push(`/users/${user?.userId}`)
            }
        },
        {
            separator: true
        },
        {
            icon: loading ? 'pi pi-spin pi-spinner' : PrimeIcons.GLOBE,
            label: tawreedTranslationService.translate('lbl_language_toggle'),
            command: () => onLanguage(),
        },
        {
            icon: PrimeIcons.POWER_OFF,
            label: tawreedTranslationService.translate('lbl_logout'),
            command: () => onLogout(),
        }
    ];


    const onLogout = () => {
        new TawreedAction(
            TawreedActionName.Logout,
            'statefull',
            'lbl_logout',
            'pi pi-power-off',
            () => new Promise((resolve) => {
                new AuthActions().logout(authDispatch, constantsDispatch, () => history.push(Routes.Login));
                resolve();
            }),
            undefined,
            true)
            .execute()
            .then();
    }

    const onLanguage = () => {
        const ltr = 'en';
        const rtl = 'ar';

        new AuthActions().setLanguage(user?.prefLangCode === ltr ? rtl : ltr, authDispatch);
    }

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (menu && menu.current) {
            menu.current.toggle(event)
            if (props.onClick) {
                props.onClick(event);
            }
        }
    }

    return (
        <React.Fragment>
            <Menu ref={menu} model={items} popup id="tawreed_dropdown_user" style={{ direction: 'ltr' }} />
            <button className={props.className} onClick={onClick} title={user?.fullName ?? ''} aria-controls="tawreed_dropdown_user" aria-haspopup>
                <i className={PrimeIcons.USER} />
                <span>{user?.fullName}</span>
            </button>
        </React.Fragment>
    );
};
