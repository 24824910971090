export enum UserRole {
    Administrator = 1,
    Manager,
    Delivery,
    Sales,
    Store,
    Customer,
    SysAdmin,
    StoreDelivery,
    Finance,
    TopUpAdmin,
    WalletAdmin,
    CatManager,
    Unknown
}
