import React from "react";
import { StoreProductDto, StoreProductsService } from "../../domain";
import { TawreedAutoComplete, TawreedControlRenderModeWithoutNested, TawreedControlRules } from "../../../../../common/components/form";
import { AutoCompleteSelectParams } from "primereact/autocomplete";
import { AuthUtils } from "../../../../auth/domain";

export type StoreProductSellerAutoCompleteComponentProps = {
    //
    service?: StoreProductsService;

    // autocomplete
    autoCompleteName: string;
    autoCompleteRender: TawreedControlRenderModeWithoutNested;
    autoCompleteInputId?: string;
    autoCompleteDisabled?: boolean;
    autoCompleteRules: TawreedControlRules;
    autoCompleteOnSelect?(e: AutoCompleteSelectParams): void;
    autoCompleteValue?: any;
    partnerId: number;
    autoCompleteField: string
};

export const StoreProductSellerAutoCompleteComponent: React.FC<StoreProductSellerAutoCompleteComponentProps> = (props) => {
    const service: StoreProductsService = props.service ?? new StoreProductsService();
    const search = (q: string) => {
        return service.search({ size: 10,
           
            page: 0,
            keyword: q,
            filters: { storeId: {
                operator: 'AND',
                constraints: [
                    {
                        value: props.partnerId,
                        matchMode: "equals",
                    },
                ]
            },
            active: {
                operator: 'AND',
                constraints: [
                    {
                        value: 'true',
                        matchMode: "equals",
                    },
                ]
            },
            expireDate:{
                operator: 'AND',
                constraints: [
                    {
                        value: new Date(),
                        matchMode: "dateAfter",
                    },
                ]
            }
        }
         }).then(res => res.data?.content ?? []);
    };

    const itemTemplate = (rowData: StoreProductDto) => {
        return (
            <div className="">
            <div className="flex">
                <span className="mr-auto">{AuthUtils.current().language ==="en" ? rowData.titleEn : rowData.titleAr}</span>
                
            </div>
        </div>
        );
    }

    return (
        <TawreedAutoComplete name={props.autoCompleteName}
            inputId={props.autoCompleteInputId}
            render={props.autoCompleteRender}
            disabled={props.autoCompleteDisabled}
            rules={props.autoCompleteRules}
            scrollHeight="24em"
            field={props.autoCompleteField}
            onSelect={props.autoCompleteOnSelect}
            onSearch={search}
            value={props.autoCompleteValue}
            itemTemplate={itemTemplate} />
    );
}
